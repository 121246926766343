import { useCallback, useEffect, useState, useRef } from 'react';
import { ValidationErrors } from '../components/atoms/WarningMessage/validationErrors';
// import { RouteAccessLvl } from '../pages/routes.config';
// import { removeAuthToken } from './token';
import { ErrorsResponse } from './types';

export function useAsync<T>(): {
  status: string;
  isLoading: boolean;
  isError: boolean;
  isFulfilled: boolean;
  errors: ErrorsResponse;
  data: T;
  run: (promise: Promise<T>) => Promise<T>;
  reset: () => void;
} {
  const isMountedRef = useRef<boolean>(false);
  const [status, setState] = useState('idle');
  const [data, setData] = useState({} as T);
  const [errors, setErrors] = useState<ErrorsResponse>({});
  const reset = useCallback(() => {
    setState('idle');
    setData({} as T);
    setErrors({});
  }, []);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const run = useCallback((promise: Promise<T>) => {
    reset();
    setState('pending');

    return promise
      .then((res) => {
        if (isMountedRef.current) {
          setData(res);
          setState('fulfilled');
        }
        return res;
      })
      .catch((err) => {
        const customErrors = err?.response?.data?.errors || {
          general: err?.response?.data?.title || [
            ValidationErrors.UNEXPECTED_ERROR,
          ],
        };
        if (isMountedRef.current) {
          setState('rejected');
          setErrors(customErrors);
        }

        return customErrors;
      });
  }, []);

  return {
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isFulfilled: status === 'fulfilled',
    status,
    errors,
    data,
    run,
    reset,
  };
}
