import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';

// eslint-disable-next-line no-shadow
export enum RouteAccessLvl {
  UNVERIFIABLE,
  PUBLIC,
  AUTHORIZED,
}

export type RouteComponentProps = RouteProps & {
  accessLvl: RouteAccessLvl;
};

type RoutesConfigObject = RouteComponentProps & {
  Component: React.LazyExoticComponent<() => JSX.Element>;
  path: string; // we need to be sure the CONFIG does not have an undefined path
};

type RoutesType = {
  [key: string]: RoutesConfigObject;
};

export const ROUTES: RoutesType = {
  sharedPainting: {
    Component: lazy(() => import('./SharedPainting/SharedPainting')),
    accessLvl: RouteAccessLvl.UNVERIFIABLE,
    path: '/shared',
  },
  signIn: {
    Component: lazy(() => import('./SignIn/SignIn')),
    accessLvl: RouteAccessLvl.PUBLIC,
    path: '/signin',
  },
  signUp: {
    Component: lazy(() => import('./SignUp/SignUp')),
    accessLvl: RouteAccessLvl.PUBLIC,
    path: '/signup',
  },

  passwordReset: {
    Component: lazy(() => import('./PasswordReset/PasswordReset')),
    accessLvl: RouteAccessLvl.PUBLIC,
    path: '/resetpassword',
  },
  passwordEmailSent: {
    Component: lazy(() => import('./PasswordEmailSent/PasswordEmailSent')),
    accessLvl: RouteAccessLvl.PUBLIC,
    path: '/passwordemailsent',
  },
  passwordChange: {
    Component: lazy(() => import('./PasswordChange/PasswordChange')),
    accessLvl: RouteAccessLvl.PUBLIC,
    path: '/changepassword',
  },
  passwordConfirmed: {
    Component: lazy(() => import('./PasswordConfirmed/PasswordConfirmed')),
    accessLvl: RouteAccessLvl.PUBLIC,
    path: '/passwordconfirmed',
  },

  confirmEmail: {
    Component: lazy(() => import('./ConfirmEmail/ConfirmEmail')),
    accessLvl: RouteAccessLvl.PUBLIC,
    path: '/confirmemail',
  },
  externalLoginCallback: {
    Component: lazy(
      () => import('./ExternalLoginCallback/ExternalLoginCallback'),
    ),
    accessLvl: RouteAccessLvl.PUBLIC,
    path: '/external-login-callback',
  },
  checkYourEmail: {
    Component: lazy(
      () => import('./EmailConfirmationMessage/EmailConfirmationMessage'),
    ),
    accessLvl: RouteAccessLvl.PUBLIC,
    path: '/checkyouremail',
  },

  home: {
    Component: lazy(() => import('./Dashboard/Dashboard')),
    accessLvl: RouteAccessLvl.AUTHORIZED,
    path: '/',
    exact: true,
  },
  designGuide: {
    Component: lazy(() => import('./DesignGuide/DesignGuide')),
    accessLvl: RouteAccessLvl.AUTHORIZED,
    path: '/designguide',
  },
  dashboard: {
    Component: lazy(() => import('./Dashboard/Dashboard')),
    accessLvl: RouteAccessLvl.AUTHORIZED,
    path: '/dashboard',
  },

  userProfile: {
    Component: lazy(() => import('./UserProfile/UserProfile')),
    accessLvl: RouteAccessLvl.AUTHORIZED,
    path: '/user',
  },

  canvasChoose: {
    Component: lazy(() => import('./CanvasChoose/CanvasChoose')),
    accessLvl: RouteAccessLvl.AUTHORIZED,
    path: '/choosecanvas',
  },

  connectionError: {
    Component: lazy(() => import('./ConnectionError/ConnectionError')),
    accessLvl: RouteAccessLvl.PUBLIC,
    path: '/connectionerror',
  },
  eventsPage: {
    Component: lazy(() => import('./EventsPage/EventsPage')),
    accessLvl: RouteAccessLvl.AUTHORIZED,
    path: '/events',
  },
  myPictures: {
    Component: lazy(() => import('./PaintingsPage/PaintingsPage')),
    accessLvl: RouteAccessLvl.AUTHORIZED,
    path: '/paintings',
  },
  sketchesPage: {
    Component: lazy(() => import('./SketchesPage/SketchesPage')),
    accessLvl: RouteAccessLvl.AUTHORIZED,
    path: '/sketches',
  },
  tutorialsPage: {
    Component: lazy(() => import('./TutorialsPage/TutorialsPage')),
    accessLvl: RouteAccessLvl.AUTHORIZED,
    path: '/tutorials',
  },
  paypal: {
    Component: lazy(() => import('./PayPal/PayPal')),
    accessLvl: RouteAccessLvl.AUTHORIZED,
    path: '/paypal/:plan',
  },
  paymentPlan: {
    Component: lazy(() => import('./ChoosePaymentPlan/ChoosePaymentPlan')),
    accessLvl: RouteAccessLvl.AUTHORIZED,
    path: '/paymentplan',
  },
  pageNotFound: {
    Component: lazy(() => import('./PageNotFound/PageNotFound')),
    accessLvl: RouteAccessLvl.PUBLIC,
    path: '*',
  },
};

export const getRoutAfterLogin = () => ROUTES.dashboard;
export const getRoutIfNotAuthorized = () => ROUTES.signIn;

export const STATIC_LINKS = {
  googleSignUp: `${process.env.REACT_APP_API_URL}/Identity/google-login`,
  googleSignIn: `${process.env.REACT_APP_API_URL}/Identity/google-login`,
  privacyAndPolicy: `/docs/Privacy%20Policy%20Glaze.pdf`,
  termsOfUse: `/docs/Terms%20of%20Use%20Glaze.pdf`,
};
