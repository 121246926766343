import React from 'react';
import styled, { keyframes } from 'styled-components';

const DIAMETER = 2.5;

const rotate = keyframes`
  from {
    transform: rotate(0deg); 
  }

  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  width: ${DIAMETER}rem;
  height: ${DIAMETER}rem;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.textSuperDark};
  padding: ${DIAMETER / 4}rem;
  animation: ${rotate} ${({ theme }) => theme.duration.slow} linear infinite;
`;

const Left = styled.div`
  width: ${DIAMETER / 2}rem;
  height: ${DIAMETER / 4}rem;
  border-top-left-radius: ${DIAMETER / 4}rem;
  border-top-right-radius: ${DIAMETER / 4}rem;
  border-bottom: 0;
  background-color: ${({ theme }) => theme.colors.buttonDisable.background};
`;

const Right = styled.div`
  width: ${DIAMETER / 2}rem;
  height: ${DIAMETER / 4}rem;
  border-bottom-left-radius: ${DIAMETER / 4}rem;
  border-bottom-right-radius: ${DIAMETER / 4}rem;
  border-top: 0;
  background-color: ${({ theme }) => theme.colors.buttonDisable.color};
`;

export default function Animation() {
  return (
    <Wrapper>
      <Left />
      <Right />
    </Wrapper>
  );
}
