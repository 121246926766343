import React from 'react';
import styled from 'styled-components';
import i18n from 'i18next';

const Wrapper = styled.div`
  display: flex;
  color: #000;

  @media (min-width: 768px) {
    color: #fff;
  }
`;

const Icon = styled.svg`
  height: 24px;
  width: 24px;
  margin-right: 10px;

  @media (min-width: 768px) {
    fill: #fff;
  }
`;

const LangButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  color: #000;
  font-weight: bold;

  @media (min-width: 768px) {
    color: #fff;
  }
`;

interface ChangeLanguageProps {
  className?: string;
}

const ChangeLanguage = ({ className }: ChangeLanguageProps) => {
  const setLanguage = (lang: 'pl' | 'en'): void => {
    i18n.changeLanguage(lang);
  };

  return (
    <Wrapper className={className}>
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.768 73.768">
        <path d="M36.884-.003a36.884 36.884 0 1 0 36.884 36.884A36.926 36.926 0 0 0 36.884-.003ZM70.73 35.38H55.644a48.681 48.681 0 0 0-3.047-16.068 36.786 36.786 0 0 0 8.781-5.808 33.752 33.752 0 0 1 9.352 21.879ZM38.384 4.308a36.534 36.534 0 0 1 6.069 6.387 39.467 39.467 0 0 1 4.176 7.028 33.843 33.843 0 0 1-10.245 2.061Zm3.534-.935a33.762 33.762 0 0 1 17.292 8.051 33.809 33.809 0 0 1-7.772 5.116 41.252 41.252 0 0 0-9.52-13.164Zm-12.45 7.324a36.615 36.615 0 0 1 5.916-6.261v15.35a33.789 33.789 0 0 1-10.116-2.013 39.5 39.5 0 0 1 4.2-7.076Zm-7.013 5.906a33.8 33.8 0 0 1-7.9-5.177 33.757 33.757 0 0 1 17.469-8.074 41.244 41.244 0 0 0-9.569 13.252Zm12.929 6.183v12.6H21.278a45.607 45.607 0 0 1 2.835-14.838 36.83 36.83 0 0 0 11.271 2.239Zm0 15.6v12.386a36.8 36.8 0 0 0-11.018 2.146 42.373 42.373 0 0 1-3.088-14.534Zm0 15.386v15.252a47.106 47.106 0 0 1-9.792-13.361 33.819 33.819 0 0 1 9.792-1.893Zm-2.86 16.708a33.755 33.755 0 0 1-18.084-8.24 33.786 33.786 0 0 1 8.306-5.426 48.955 48.955 0 0 0 9.778 13.664Zm5.86-1.313v-15.4a33.8 33.8 0 0 1 9.922 1.94 47.081 47.081 0 0 1-9.922 13.458Zm12.762-12.294a33.846 33.846 0 0 1 8.182 5.367 33.759 33.759 0 0 1-17.909 8.217 48.888 48.888 0 0 0 9.727-13.586Zm-12.762-6.1V38.384h14.257a42.352 42.352 0 0 1-3.106 14.582 36.818 36.818 0 0 0-11.151-2.196Zm0-15.386v-12.6a36.806 36.806 0 0 0 11.4-2.291 45.562 45.562 0 0 1 2.854 14.888ZM12.39 13.508a36.8 36.8 0 0 0 8.91 5.871 48.7 48.7 0 0 0-3.022 16.005H3.038a33.757 33.757 0 0 1 9.352-21.876ZM3.038 38.384h15.24a45.675 45.675 0 0 0 3.256 15.683 36.807 36.807 0 0 0-9.256 6.08 33.755 33.755 0 0 1-9.24-21.763Zm58.447 21.764a36.8 36.8 0 0 0-9.122-6.022 45.69 45.69 0 0 0 3.279-15.742H70.73a33.759 33.759 0 0 1-9.245 21.764Z" />
      </Icon>
      <LangButton type="button" onClick={() => setLanguage('pl')}>
        PL
      </LangButton>
      &nbsp;/&nbsp;
      <LangButton type="button" onClick={() => setLanguage('en')}>
        EN
      </LangButton>
    </Wrapper>
  );
};

export default ChangeLanguage;
