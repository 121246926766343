import jwt_decode from 'jwt-decode';
import { getAuthToken } from '../api/token';

import { TokenTypes } from '../types';

const minRequiredExpTimeInHours = 12;

export default function checkTokenExp() {
  // in this function, we check if the token will not expire too quickly, disrupting the painting in the painting application
  const token = getAuthToken();

  if (!token) return false;

  const decodedToken = jwt_decode<TokenTypes>(token);
  const expDate = new Date(decodedToken.exp * 1000);

  const minExpDate = new Date(
    new Date().setHours(new Date().getHours() + minRequiredExpTimeInHours),
  );

  if (expDate < minExpDate) return false;

  return true;
}
