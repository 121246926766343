/* eslint-disable no-shadow */
export type UserPermission = {
  id: string;
  descriptoion: string;
};

export enum UserFormTypes {
  DD = 'DD',
  MM = 'MM',
  YYYY = 'YYYY',
  countryName = 'countryName',
  userPlan = 'userPlan',
  userEmail = 'userEmail',
  newsletter = 'newsletter',
}

export enum PaymentStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  CANCEL = 'cancel',
}

export enum PaymentPlans {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export type User = {
  role: 'Admin' | 'Moderator' | 'Basic' | 'Premium';
  permissions: UserPermission[];
};

export type TokenTypes = {
  email: string;
  language: string;
  newsletter: string;
  lengthUnitType: string;
  menuPosition: string;
  animatedMenu: string;
  birthDate?: string;
  countryName?: string;
  exp: number;
  iat: number;
  jti: number;
  nbf: number;
  role: string;
  sub: string;
};

export enum SliderTypes {
  EVENTS = 'EVENTS',
  PAINTINGS = 'PAINTINGS',
  SKETCHES = 'SKETCHES',
  VIDEOS = 'VIDEOS',
}

export type FormValidationErrors = string[];

export type FormErrors = {
  [key: string]: FormValidationErrors;
};

export enum MaterialTypes {
  CANVAS = 'Canvas',
  BOARD = 'Board',
}

export type Material = {
  name: MaterialTypes;
  background: string;
  thumbnailBg: string;
};

export type Materials = {
  [key in MaterialTypes]: Material;
};

export type CanvasSize = {
  name?: string;
  text?: string;
  width: number;
  height: number;
};

export type CanvasSizes = CanvasSize[];
