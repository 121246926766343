const AUTHTOKEN = 'authToken';

export function setAuthToken(token: string) {
  window.localStorage.setItem(AUTHTOKEN, token);
}

export function removeAuthToken() {
  window.localStorage.removeItem(AUTHTOKEN);
}

export function getAuthToken() {
  try {
    return window.localStorage.getItem(AUTHTOKEN);
  } catch {
    return null;
  }
}
