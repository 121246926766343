import { AxiosResponse } from 'axios';
/* eslint-disable class-methods-use-this */
import axios from './http';
import { removeAuthToken, setAuthToken } from './token';
import {
  AuthResponse,
  RegisterRequest,
  LoginRequest,
  ConfirmEmailRequest,
  ChangePasswordRequest,
  ReCaptchaVerifyRequest,
  EventsResponse,
  SketchesResponse,
  TutorialsResponse,
  User,
  CreatePaintingResponse,
  Painting,
} from './types';

class HttpClient {
  public register({
    email,
    password,
    passwordConfirmation,
    terms,
    newsletter,
    language,
  }: RegisterRequest) {
    return axios.post<void, AuthResponse>('/Identity/register', {
      email,
      password,
      passwordConfirmation,
      terms,
      newsletter,
      language,
    });
  }

  public async login({ email, password }: LoginRequest) {
    return axios
      .post<void, AuthResponse>('/Identity/login', {
        email,
        password,
      })
      .then(({ data }) => setAuthToken(data.token));
  }

  public logout() {
    return axios
      .post<void, AuthResponse>('/Identity/logout')
      .then(() => removeAuthToken());
  }

  public remindPassword({ email }: LoginRequest) {
    return axios.post<void, AuthResponse>('/Identity/remind-password', {
      email,
    });
  }

  public confirmEmail({ id, token }: ConfirmEmailRequest) {
    return axios
      .get<void, AuthResponse>(
        `/Identity/confirm-email?Id=${id}&Token=${token}`,
      )
      .then(({ data }) => setAuthToken(data.token));
  }

  public async confirmGoogle(token: string) {
    return axios
      .post<void, AuthResponse>('/Identity/google-login', {
        googleToken: token,
      })
      .then(({ data }) => setAuthToken(data.token));
  }

  public changePassword({ id, token, newPassword }: ChangePasswordRequest) {
    return axios.put<void, AuthResponse>(
      `/Identity/password?Id=${id}&Token=${token}`,
      {
        newPassword,
      },
    );
  }

  public checkUserPremision() {
    return axios
      .get<void, AxiosResponse<string>>('/Users/premiumStatus')
      .then((res) => res.data);
  }

  public checkUserTrial() {
    return axios
      .get<void, AxiosResponse<string>>('/Users/trialEndDate')
      .then((res) => res.data);
  }

  public activateTrial() {
    return axios
      .post<void, AxiosResponse<string>>('/Users/startTrial')
      .then((res) => res.data);
  }

  public changeUserData(formValues: User) {
    return axios
      .put<void, AuthResponse>('/Users', formValues)
      .then(({ data }) => setAuthToken(data.token));
  }

  public verifyReCaptcha({ token }: ReCaptchaVerifyRequest) {
    return axios.post<void, AuthResponse>('/ReCaptcha/verify', {
      token,
    });
  }

  public payments(id: string) {
    return axios
      .post<void, AuthResponse>('/Orders', {
        orderId: id,
      })
      .then((res) => res.data);
  }

  public getCountries() {
    return axios
      .get<void, AxiosResponse<string[]>>('/Countries')
      .then((res) => res.data);
  }

  public getPaintings() {
    return axios
      .get<void, AxiosResponse<Painting[]>>('/Painting')
      .then((res) => res.data);
  }

  public getPaintingThumbnail(id: string) {
    return axios
      .get<void, AxiosResponse<string>>(`/Painting/${id}/thumbnail`, {
        responseType: 'blob',
      })
      .then((res) => URL.createObjectURL(res.request.response));
  }

  public getEvents(location = '---') {
    return axios
      .get<void, AxiosResponse<EventsResponse>>('/Event', {
        params: {
          country: location,
          timezone: (new Date().getTimezoneOffset() / 60) * -1,
        },
      })
      .then((res) => res.data);
  }

  public getEventLogo(img: string) {
    return axios
      .get<void, AxiosResponse<string>>(`/Event/images/${img}`, {
        responseType: 'blob',
      })
      .then((res) => URL.createObjectURL(res.request.response));
  }

  public getEventPhoto(img: string) {
    return axios
      .get<void, AxiosResponse<string>>(`/Event/images/${img}`, {
        responseType: 'blob',
      })
      .then((res) => URL.createObjectURL(res.request.response));
  }

  public getSketches() {
    return axios
      .get<void, AxiosResponse<SketchesResponse>>('/Sketch')
      .then((res) => res.data);
  }

  public getSketchThumbnail(id: string, img: string) {
    return axios
      .get<void, AxiosResponse<string>>(`/Sketch/${id}/images/${img}`, {
        responseType: 'blob',
      })
      .then((res) => URL.createObjectURL(res.request.response));
  }

  public getTutorials() {
    return axios
      .get<void, AxiosResponse<TutorialsResponse>>('/Tutorial')
      .then((res) => res.data);
  }

  public createPainting(
    size: {
      width: number;
      height: number;
    },
    activeMaterial: number,
  ) {
    return axios
      .post<void, AxiosResponse<CreatePaintingResponse>>('/Painting', {
        canvasHeight: size.height,
        canvasWidth: size.width,
        canvasType: activeMaterial,
      })
      .then((res) => res.data);
  }

  public removePainting(paintingId: string) {
    return axios
      .delete<void, AxiosResponse<CreatePaintingResponse>>(
        `/Painting/${paintingId}`,
      )
      .then((res) => res.data);
  }

  public setPaymentStatusAsPending() {
    return axios.post<void, AuthResponse>('/users/pendingPayment');
  }

  public async shareImage(imageId: string): Promise<string> {
    return axios.post(`/Painting/${imageId}/share`).then((res) => res.data);
  }

  public downloadPainting(id: string) {
    return axios
      .get<void, AxiosResponse<string>>(`/painting/${id}/image`, {
        responseType: 'blob',
      })
      .then((res) => URL.createObjectURL(res.request.response));
  }

  public createPaintingFromSketch(sketchId: string) {
    return axios
      .post<void, AxiosResponse<CreatePaintingResponse>>(
        `/painting/sketch/${sketchId}`,
      )
      .then((res) => res.data);
  }
}

export const api = new HttpClient();
