import { useState } from 'react';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 816px;
  max-width: 94vw;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 25px;
  border-radius: 50px;
  background: black;
`;

const Text = styled.span`
  font-weight: 200;
  width: 80%;
  font-size: 1rem;
`;

const CookiePolicyLink = styled.a`
  color: #e7e7e7;
`;

const AcceptCookiesBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  color: white;
  font-weight: 700;
  padding: 12px;
  border-radius: 50px;
  transition: 0.2s;
  background: #0f0f0f;
  cursor: pointer;

  &:hover {
    background: #1b1b1b;
  }
`;

export default function CookiesBar() {
  const cookies = new Cookies();
  const { t } = useTranslation();

  const [isCookiesAccepted, setCookiesAcceptance] = useState(
    cookies.get('glaze_cookiesAccepted'),
  );

  const handleAcceptTerms = () => {
    cookies.set('glaze_cookiesAccepted', true, {
      path: '/',
      maxAge: 60 * 60 * 24 * 30 * 12,
    });
    setCookiesAcceptance(true);
  };

  if (isCookiesAccepted) {
    return null;
  }

  return (
    <Wrapper>
      <Text>
        {t(
          `We use cookies to ensure you get the best experience on our site. Here's our `,
        )}
        <CookiePolicyLink
          target="_blank"
          href="https://admin.wellofart.com/api/policies/privacy"
        >
          {t('cookie policy')}
        </CookiePolicyLink>
      </Text>
      <AcceptCookiesBtn type="button" onClick={handleAcceptTerms}>
        OK
      </AcceptCookiesBtn>
    </Wrapper>
  );
}
