import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { api } from '../api';
import { useAsync } from '../api/useAsync';

import { RouteAccessLvl } from '../pages/routes.config';

export function useAuthHook(
  userAccessLvl: RouteAccessLvl,
  setUserAccessLvl: React.Dispatch<React.SetStateAction<RouteAccessLvl>>,
) {
  const location = useLocation();

  const { status, errors, run, reset } = useAsync();
  const signUp = useCallback(
    ({ email, password, passwordConfirmation, terms, newsletter, language }) =>
      run(
        api.register({
          email,
          password,
          passwordConfirmation,
          terms,
          newsletter,
          language,
        }),
      ),
    [run],
  );

  const signIn = useCallback(
    ({ email, password }) => {
      run(
        api.login({ email, password }).then(() => {
          setUserAccessLvl(RouteAccessLvl.AUTHORIZED);
        }),
      );
    },
    [run],
  );

  const confirmEmailAndSingIn = useCallback(
    ({ id, token }) => {
      run(
        api.confirmEmail({ id, token }).then(() => {
          setUserAccessLvl(RouteAccessLvl.AUTHORIZED);
        }),
      );
    },
    [run],
  );

  const confirmGoogleAndSingIn = useCallback(
    (token: string) => {
      run(
        api.confirmGoogle(token).then(() => {
          setUserAccessLvl(RouteAccessLvl.AUTHORIZED);
        }),
      );
    },
    [run],
  );

  const signOut = useCallback(async () => {
    setUserAccessLvl(RouteAccessLvl.PUBLIC);
    api.logout();
  }, []);

  useEffect(() => {
    reset();
  }, [location, reset]);

  return {
    status,
    errors,
    signUp,
    signIn,
    signOut,
    confirmEmailAndSingIn,
    confirmGoogleAndSingIn,
  };
}
