import { Suspense } from 'react';
import { Switch } from 'react-router-dom';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import jwt_decode from 'jwt-decode';
import AuthRoute from './components/auth/AuthRoute/AuthRoute';
import Loader from './components/molecules/Loader/Loader';
import { CookiesBar } from './components/organisms/CookiesBar';
import { ROUTES } from './pages/routes.config';
import { UserProvider } from './state/UserContex';
import { getAuthToken } from './api/token';
import { TokenTypes } from './types';

const token = getAuthToken();
const tokenData = token ? jwt_decode<TokenTypes>(token) : ({} as TokenTypes);
const lng = tokenData?.language || 'en';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng,
    fallbackLng: 'en',
    resources: {
      en: {
        translation: {
          'local time': 'local time',
          ongoing: 'ongoing',
          'No date': 'No date',
          'Buy Premium': 'Buy Premium',
          'try for free': 'try for free',
          'Removed image cannot be recovered':
            'Removed image cannot be recovered',
          Delete: 'Delete',
          cancel: 'cancel',
          'You will be signed out': 'You will be signed out',
          Confirm: 'Confirm',
          'No Title': 'No Title',
          'No Description': 'No Description',
          'Something went Wrong': 'Something went Wrong',
          'Explore the full potential of tools and techniques.':
            'Explore the full potential of tools and techniques.',
          'payment cancel': 'payment cancel',
          'Back to user profile': 'Back to user profile',
          'there was a problem with the load of content.':
            'there was a problem with the load of content.',
          'load again': 'load again',
          'File Limit': 'File Limit',
          'buy premium': 'buy premium',
          'Visit the Museum website': 'Visit the Museum website',
          'PROBLEM WITH IMAGE LOADING': 'PROBLEM WITH IMAGE LOADING',
          'VISIT THE GALLERY WEBSITE': 'VISIT THE GALLERY WEBSITE',
          'glaze basic': 'glaze basic',
          'glaze pro': 'glaze pro',
          'payment processing...': 'payment processing...',
          'change plan to pro': 'change plan to pro',
          'Yust click on your position.': 'Yust click on your position.',
          'Field is required': 'Field is required',
          'An account with this email already exists. Please ':
            'An account with this email already exists. Please ',
          ' login ': ' login ',
          'using this email address  or ': 'using this email address  or ',
          ' reset the password.': ' reset the password.',
          'Loading please wait.': 'Loading please wait.',
          'By creating an account, you agree to our ':
            'By creating an account, you agree to our ',
          'Privacy Policy': 'Privacy Policy',
          ' and ': ' and ',
          'Terms of Use': 'Terms of Use',
          '': '',
          email: 'email',
          'I would like to receive the Glaze Newsletter.':
            'I would like to receive the Glaze Newsletter.',
          'Create account': 'Create account',
          'Reset my password': 'Reset my password',
          'Sign in with Google': 'Sign in with Google',
          'Back to sign in': 'Back to sign in',
          Next: 'Next',
          Profile: 'Profile',
          'Sign out': 'Sign out',
          'No paintings.': 'No paintings.',
          'Create new canvas': 'Create new painting',
          'View more': 'View more',
          'You can help us improve it.': 'You can help us improve it.',
          'Send us a message.': 'Send us a message.',
          'No events.': 'No events.',
          'Canvas material': 'Canvas material',
          'Create a new painting': 'Create a new painting',
          'Choose the type of the canvas you want to use.':
            'Choose the type of the canvas you want to use.',
          Canvas: 'Canvas',
          'password reset': 'password reset',
          save: 'save',
          'sign out': 'sign out',
          'click here': 'click here',
          'No tutorials.': 'No tutorials.',
          cost: 'cost',
          month: 'month',
          'try it': 'try it',
          'Password reset.': 'Password reset.',
          'Canvas size': 'Canvas size',
          'Educational films': 'Educational films',
          'Watch educational videos.': 'Watch educational videos.',
          Sketches: 'Sketches',
          'See available sketches.': 'See available sketches.',
          'My paintings': 'My paintings',
          Events: 'Events',
          'sign in to glaze.': 'sign in to glaze.',
          'or use mail to sign in': 'or use mail to sign in',
          'Create an account': 'Create an account',
          'No sketches.': 'No sketches.',
          'Create new account.': 'Create new account.',
          'or use mail to create new account':
            'or use mail to create new account',
          'Sign in to your account': 'Sign in to your account',
          'Enter your new password below.': 'Enter your new password below.',
          'GO TO GLAZE WEBSITE': 'GO TO GLAZE WEBSITE',
          'was sent.': 'was sent.',
          'Checking data, please wait.': 'Checking data, please wait.',
          'Confirm email.': 'Confirm email.',
          'Something went wrong.': 'Something went wrong.',
          'New user? ': 'New user?',
          'Welcome!': 'Welcome!',
          'Forgot your password?': 'Forgot your password?',
          password: 'password',
          'Password does not meet minimum requirements, add one lowercase character.':
            'Password does not meet minimum requirements, add one lowercase character.',
          'Password does not meet minimum requirements, add one uppercase character.':
            'Password does not meet minimum requirements, add one uppercase character.',
          'Password does not meet minimum requirements, add one digit character.':
            'Password does not meet minimum requirements, add one digit character.',
          'Please enter a valid email address.':
            'Please enter a valid email address.',
          'Passwords must match.': 'Passwords must match.',
          'You need to accept our Terms of Service and Privacy Policy to create a Glaze account.':
            'You need to accept our Terms of Service and Privacy Policy to create a Glaze account.',
          'An account with this email already exists. Please login using this email address or reset the password.':
            'An account with this email already exists. Please login using this email address or reset the password.',
          'Wrong email or password, please try again.':
            'Wrong email or password, please try again.',
          'Account not verified. Check your mailbox.':
            'Account not verified. Check your mailbox.',
          'The server is taking too long to respond. Please check your internet connection and try again or contact your system administrator.':
            'The server is taking too long to respond. Please check your internet connection and try again or contact your system administrator.',
          'Fill all fields': 'Fill all fields',
          '*Required field': '*Required field',
          "We use cookies to ensure you get the best experience on our site. Here's our ":
            "We use cookies to ensure you get the best experience on our site. Here's our ",
          'cookie policy': 'cookie policy',
          "Enter your login email address and we'll send you a link to reset your password.":
            "Enter your login email address and we'll send you a link to reset your password.",
          link: 'link',
          'new painting': 'new painting',

          'A link has been sent to the e-mail address provided to verify your account. Check your email and follow the instructions. ':
            'A link has been sent to the e-mail address provided to verify your account. Check your email and follow the instructions. ',
          "If you don't see a message in your inbox, check your spam or junk mail folder. For some mail providers, it may take up to 24h to transfer the message. If you have any questions, contact us: contact@wellofart.com":
            "If you don't see a message in your inbox, check your spam or junk mail folder. For some mail providers, it may take up to 24h to transfer the message. If you have any questions, contact us: contact@wellofart.com",
          'password-min-length':
            'Password does not meet minimum requirements, minimum of {{length}} characters.',
          'Sign up with Google': 'Sign in with Google',
          'Have login?': 'Have login?',
          'Verification email': 'Verification email ',
          "You don't have any images saved. You can change this.":
            "You don't have any images saved. You can change this.",
          'Click here and create a new one!':
            'Click here and create a new one!',
          "Google couldn't sign you in": "Google couldn't sign you in",
          dashboard: 'dashboard',
          'new canvas': 'new canvas',
          'my paintings': 'my paintings',
          'educational sketch': 'educational sketch',
          'educational tutorials': 'educational tutorials',
          events: 'events',
          'Choose from the available types of material the canvas you want to paint on.':
            'Choose from the available types of material the canvas you want to paint on.',
          Size: 'Size',
          paintings: 'paintings',
          'Select the painting you want to continue.':
            'Select the painting you want to continue.',
          'exercise sketches': 'exercise sketches',
          'Select the sketch you want to paint.':
            'Select the sketch you want to paint.',
          education: 'education',
          'Select the movie you are interested in.':
            'Select the movie you are interested in.',
          'current plan:': 'current plan:',
          'Start trial': 'Start trial',
          'Trial active': 'Trial active',
          'Trial expired': 'Trial expired',
          'Your plan is': 'Your plan is',
          'Date of birth': 'Date of birth',
          'CM or INCH?': 'CM or INCH?',
          language: 'language',
          'payment plan': 'payment plan',
          'buy it': 'buy it',
          'monthly access': 'monthly access',
          'yearly access': 'yearly access',
          'select payment method': 'select payment method',
          'You cancel a payment, back to user profile and try again later':
            'You cancel a payment, back to user profile and try again later',
          'Sorry we have a problem with making a payment back to user profile and try again later':
            'Sorry we have a problem with making a payment. Go back to user profile and try again later',
          'Payment successful!': 'Payment successful!',
          'Modal Buy Pro': 'Buy Pro',
          'You need to buy premium to choose canvas, or you can use basic plan and go to painting app':
            'You have to buy Pro Plan to choose canvas, or you can use basic plan and go to painting app',
          'modal try for free': 'try for free',
        },
      },
      pl: {
        translation: {
          'local time': 'czas lokalny',
          ongoing: 'trwa',
          'No date': 'Brak daty',
          'Buy Pro': 'Kup Pro',
          'Try for free': 'Spróbuj za darmo',
          'Removed image cannot be recovered':
            'Usunięty obraz nie może być odzyskany',
          Delete: 'Usuń',
          Cancel: 'Anuluj',
          'You will be signed out': 'Zostaniesz wylogowany',
          Confirm: 'Potwierdź',
          'No Title': 'Brak Tytułu',
          'No Description': 'Brak Opisu',
          'Something went wrong': 'Coś poszło nie tak',
          'Explore the full potential of tools and techniques.':
            'Poznaj pełny potencjał narzędzi i technik.',
          'payment cancel': 'Płatność anulowana',
          'Back to user profile': 'Powrót do profilu użytkownika',
          'There was a problem with the load of content.':
            'Wystąpił problem z załadowaniem treści.',
          'Load again': 'Wczytaj ponownie',
          'File limit': 'Limit plików',
          'buy pro': 'kup pro',
          'Visit the Museum website': 'Odwiedź stronę muzeum',
          'PROBLEM WITH IMAGE LOADING': 'PROBLEM Z ZAŁADOWANIEM OBRAZU',
          LINK: 'LINK',
          'glaze basic': 'glaze basic',
          'glaze pro': 'glaze pro',
          'payment processing...': 'przetwarzanie płatności...',
          'change plan to pro': 'zmień plan na pro',
          'Just click on your position.': 'Wybierz swoje miejsce',
          'Field is required': 'Pole jest wymagane',
          'An account with this email already exists. Please ':
            'Konto z podanym adresem email już istnieje. Proszę ',
          ' login ': ' zaloguj się ',
          'using this email address  or ': 'używając tego adresu email lub ',
          ' reset the password.': ' zresetuj hasło.',
          'Loading please wait.': 'Ładowanie, proszę czekać.',
          'By creating an account, you agree to our ':
            'Zakładając konto zgadzasz się z ',
          'Privacy Policy': 'Polityka Prywatności',
          ' and ': ' oraz ',
          'Terms of Use': 'Warunki Korzystania',
          '': '',
          email: 'email',
          'I would like to receive the Glaze Newsletter.':
            'Chciałbym otrzymywać newsletter Glaze.',
          'Create account': 'Załóż konto',
          'Reset my password': 'Zresetuj hasło',
          'Sign in with Google': 'Zaloguj się przez konto Google',
          'Back to sign in': 'Powrót do strony logowania',
          Next: 'Dalej',
          Profile: 'Profil',
          'Sign out': 'Wyloguj się',
          'No paintings.': 'Brak obrazów.',
          'Create new canvas': 'Stwórz nowy obraz',
          'View more': 'Zobacz więcej',
          'You can help us improve it.': 'Możesz nam pomóc go ulepszyć.',
          'Send us a message.': 'Wyślij nam wiadomość.',
          'No events.': 'Brak wydarzeń.',
          'Canvas material': 'Materiał tła',
          'Create a new painting': 'Stwórz nowy obraz',
          'Choose the type of the canvas you want to use.':
            'Wybierz typ tła którego chcesz użyć.',
          Canvas: 'Tło',
          'password reset': 'zresetuj hasło',
          save: 'zapisz',
          'sign out': 'wyloguj',
          'click here': 'kliknij tutaj',
          'No tutorials.': 'Brak tutoriali.',
          cost: 'cena',
          month: 'miesiąc',
          'try it': 'spróboj',
          'Password reset.': 'Zresetuj hasło.',
          'Canvas size': 'Rozmiar tła',
          'Educational films': 'Filmy edukacyjne',
          'Watch educational videos.': 'Zobacz filmy edukacyjne.',
          Sketches: 'Szkice',
          'See available sketches.': 'Zobacz dostępne szkice.',
          'My paintings': 'Moje obrazy',
          Events: 'Wydarzenia',
          'sign in to glaze.': 'zaloguj się do Glaze.',
          'or use mail to sign in': 'lub użyj adresu email do zalogowania',
          'Create an account': 'Załóż konto',
          'No sketches.': 'Brak szkicy.',
          'Create new account.': 'Załóż nowe konto.',
          'or use mail to create new account':
            'lub użyj adresu email do założenia nowego konta',
          'Sign in to your account': 'Zaloguj się na swoje konto.',
          'Enter your new password below.': 'Wpisz nowe hasło.',
          'GO TO GLAZE WEBSITE': 'IDZ NA STRONĘ GLAZE',
          'was sent.': 'został wysłany.',
          'Checking data, please wait.': 'Sprawdzamy dane, Proszę czekać.',
          'Confirm email.': 'Potwierdź adres email.',
          'Something went wrong.': 'Coś poszło nie tak.',
          'New user? ': 'Nowy użytkownik?',
          'Welcome!': 'Witaj!',
          'Forgot your password?': 'Nie pamiętasz hasła?',
          password: 'hasło',
          'Password does not meet minimum requirements, add one lowercase character.':
            'Hasło nie spełnia minimalnych wymogów, dodaj jedną małą literę.',
          'Password does not meet minimum requirements, add one uppercase character.':
            'Hasło nie spełnia minimalnych wymogów, dodaj jedną dużą literę.',
          'Password does not meet minimum requirements, add one digit character.':
            'Hasło nie spełnia minimalnych wymogów, dodaj jedną cyfrę.',
          'Please enter a valid email address.':
            'Proszę wprowadź poprawny adres email.',
          'Passwords must match.': 'Hasła muszą się zgadzać.',
          'You need to accept our Terms of Service and Privacy Policy to create a Glaze account.':
            'Musisz zaakceptować Warunki Korzystania oraz Politykę Prywatności, aby stworzyć konto Glaze.',
          'An account with this email already exists. Please login using this email address or reset the password.':
            'Konto o podanym mejlu już istnieje. Proszę zalogować się na podany adres email, lub zresetować hasło.',
          'Wrong email or password, please try again.':
            'Nieprawidłowy adres email lub hasło, spróbuj ponownie.',
          'Account not verified. Check your mailbox.':
            'Konto niezweryfikowane. Sprawdź swoją skrzynkę pocztową.',
          'The server is taking too long to respond. Please check your internet connection and try again or contact your system administrator.':
            'Serwer nie odpowiada zbyt długo. Sprawdź połączenie internetowe i spróbuj ponownie lub skontaktuj się z administratorem systemu.',
          'Fill all fields': 'Wypełnij wszystkie pola',
          '*Required field': '*Wymagane pole',
          "We use cookies to ensure you get the best experience on our site. Here's our ":
            'Używamy plików cookie, aby zapewnić najlepszą jakość korzystania z naszej witryny. Oto nasza ',
          'cookie policy': 'Polityka Cookie',
          "Enter your login email address and we'll send you a link to reset your password.":
            'Wpisz swój adres email logowania, a wyślemy Ci link do zresetowania hasła.',
          link: 'link',
          'new painting': 'nowy obraz',

          'A link has been sent to the e-mail address provided to verify your account. Check your email and follow the instructions. ':
            'Link weryfikacyjny został wysłany na podany adres email. Sprawdź skrzynkę email i postępuj zgodnie z instrukcjami.',
          "If you don't see a message in your inbox, check your spam or junk mail folder. For some mail providers, it may take up to 24h to transfer the message. If you have any questions, contact us: contact@wellofart.com":
            'Jeśli wiadomość nie dotarła, sprawdź folder spam. Do niektórych kont wiadomość może dotrzeć nawet po 24 godzinach. W razie pytań prosimy pisać na contact@wellofart.com',
          'password-min-length':
            'Hasło nie spełnia minimalnych wymagań: minimum {{length}} znaków.',
          'Sign up with Google': 'Załóż konto z Google',
          'Have login?': 'Masz już login?',
          'Verification email': 'E-mail weryfikacyjny',
          "You don't have any images saved. You can change this.":
            'Nie masz jeszcze żadnych obrazów.',
          'Click here and create a new one!':
            'Kliknij tutaj i stwórz nowy obraz',
          "Google couldn't sign you in": 'Google nie mógł Cię zalogować',
          dashboard: 'panel',
          'new canvas': 'nowy obraz',
          'my paintings': 'moje obrazy',
          'educational sketch': 'szkice',
          'educational tutorials': 'filmy edukacyjne',
          events: 'wydarzenia',
          'Choose from the available types of material the canvas you want to paint on.':
            'Wybierz materiał kanwy na którym chcesz malować.',
          Size: 'Rozmiar',
          paintings: 'obrazy',
          'Select the painting you want to continue.':
            'Wybierz obraz, który chciałbyś kontynuować.',
          'exercise sketches': 'szkice do ćwiczeń',
          'Select the sketch you want to paint.':
            'Wybierz szkic który chciałbyś pomalować.',
          education: 'filmy',
          'Select the movie you are interested in.':
            'Wybierz film który Cię interesuje',
          'current plan:': 'aktualny plan',
          'Start trial': 'Rozpocznij trial',
          'Trial active': 'Trial aktywny',
          'Trial expired': 'Trial wygasł',
          'Your plan is': 'Twój plan to',
          'Date of birth': 'Data urodzenia',
          'CM or INCH?': 'CM czy INCH?',
          language: 'język',
          'payment plan': 'plany płatności',
          'buy it': 'kupuję',
          'monthly access': 'dostęp na miesiąc',
          'yearly access': 'dostęp na rok',
          'select payment method': 'Wybierz metodę płatności',
          'You cancel a payment, back to user profile and try again later':
            'Twoja płatność nie powiodła się. Wróć do profilu i spróbuj ponownie później.',
          'Sorry we have a problem with making a payment back to user profile and try again later':
            'Przepraszamy, mamy problem z dokonaniem płatności. Wróć do profilu użytkownika i spróbuj ponownie później.',
          'Payment successful!': 'Płatność powiodła się!',
          'Modal Buy Pro': 'Kup Plan Pro',
          'You need to buy premium to choose canvas, or you can use basic plan and go to painting app':
            'Aby wybrać rodzaje tła musisz mieć Plan Pro. Możesz skorzystać z darmowej wersji z jednym tłem.',
          'modal try for free': 'Wypróbuj za darmo',
        },
      },
    },
  });

export default function AppRoutes() {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {Object.entries(ROUTES).map(([key, { path, Component, accessLvl }]) => (
          <AuthRoute accessLvl={accessLvl} key={key} path={path} exact>
            <UserProvider>
              <CookiesBar />
              <Component />
            </UserProvider>
          </AuthRoute>
        ))}
      </Switch>
    </Suspense>
  );
}
