import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from './components/templates/ErrorBoundary';
import App from './App';
import AppProviders from './state/AppProviders';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <AppProviders>
        <App />
      </AppProviders>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
