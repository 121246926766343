/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAuthToken, removeAuthToken } from '../../../api/token';
import ConnectionError from '../../../pages/ConnectionError/ConnectionError';

import {
  RouteAccessLvl,
  getRoutIfNotAuthorized,
  RouteComponentProps,
  getRoutAfterLogin,
} from '../../../pages/routes.config';
import { AuthContext } from '../../../state/AuthContext';
import { UserProvider } from '../../../state/UserContex';

export default function AuthRoute({
  children,
  accessLvl,
  path,
  exact,
}: RouteComponentProps) {
  const { userAccessLvl } = useContext(AuthContext);

  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        if (
          accessLvl === RouteAccessLvl.PUBLIC &&
          getAuthToken() &&
          userAccessLvl !== RouteAccessLvl.PUBLIC
        ) {
          return <Redirect to={getRoutAfterLogin().path} />;
        }
        if (userAccessLvl === RouteAccessLvl.UNVERIFIABLE) {
          return <ConnectionError />;
        }
        if (
          accessLvl === RouteAccessLvl.AUTHORIZED &&
          userAccessLvl !== RouteAccessLvl.AUTHORIZED
        ) {
          removeAuthToken();

          return <Redirect to={getRoutIfNotAuthorized().path} />;
        }

        return <UserProvider>{children}</UserProvider>;
      }}
    />
  );
}
