import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import jwt_decode from 'jwt-decode';
import { TokenTypes, UserFormTypes } from '../types';
import useUserHook from './useUserHook';
import { getAuthToken } from '../api/token';

export type UserState = {
  birthDate: {
    [key: string]: string;
  };
  userPlan: string;
  userEmail: string;
  countryName: string;
  language: string;
  newsletter: string;
  lengthUnitType: string;
  menuPosition: string;
  animatedMenu: string;
  userId: string;
};

type Props = {
  children: React.ReactNode;
};
type ConetxtValue = {
  userState: UserState;
  setUserState: Dispatch<SetStateAction<UserState>>;
  handleChangeUserInfo: (selectedItem: string, type: UserFormTypes) => void;
};

const UserContext = createContext<ConetxtValue>({} as ConetxtValue);

const UserProvider = ({ children }: Props) => {
  const [data] = useState(() => {
    const token = getAuthToken();
    return token ? jwt_decode<TokenTypes>(token) : ({} as TokenTypes);
  });

  const convertToObject = (birthDate?: string) => {
    if (birthDate) {
      const date = birthDate.split('-');
      return {
        DD: date[0],
        MM: date[1],
        YYYY: date[2],
      };
    }
    return {
      DD: '',
      MM: '',
      YYYY: '',
    };
  };

  const [userState, setUserState] = useState<UserState>({
    birthDate: convertToObject(data.birthDate),
    userPlan: data.role,
    userEmail: data.email,
    countryName: data.countryName ? data.countryName : '',
    language: data.language,
    newsletter: data.newsletter === 'True' ? 'Yes' : 'No',
    lengthUnitType: data.lengthUnitType,
    menuPosition: data.menuPosition,
    animatedMenu: data.animatedMenu === 'True' ? 'Yes' : 'No',
    userId: data.sub,
  });

  const { handleChangeUserInfo } = useUserHook(userState, setUserState);

  return (
    <UserContext.Provider
      value={{
        userState,
        setUserState,
        handleChangeUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
