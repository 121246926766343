const size = {
  tabletW: 1016,
  tabletH: 768,
};

const theme = {
  colors: {
    primary: '#E7E7E7',
    text: '#E7E7E7',
    textDark: '#737373',
    textSuperDark: '#3B3B3B',
    lightText: '#DCDCDC',
    error: '#FF2626',
    gray: '#606060',
    border: '#838383',
    dark: '#252525',
    blue: '#4285F4',
    lightBlue: '#5895FF',
    lighterBlue: '#73A6FF',
    trial: '#151a6c',
    trialLight: '#21278f',

    buttonHover: {
      background: '#2D2D2D',
      color: '#8c8c8c',
    },
    buttonDisable: {
      background: '#404040',
      color: '#8c8c8c',
    },
    buttonActive: {
      background: '#1E1E1E',
      color: '#8c8c8c',
    },
  },
  background: {
    dark: '#000',
    paper: '#3B3B3B',
    paperDarker: '#2F2F2f',
    paperLight: '#464646',
    white: '#fff',
  },
  // TODO - imporove typo PAINT-245
  typo: {
    fontSize: 16,
    fontFamily: '"Roboto", "futura-pt", "Helvetica", "Arial", sans-serif',
    headerFontFamily: '"futura-pt", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightThin: 100,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 700,
  },
  shadows: {
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.25)',
    default: '0px 16px 64px rgba(0, 0, 0, 0.3)',
  },
  radius: '0.875rem',
  duration: {
    fast: '0.1s',
    normal: '0.3s',
    slow: '0.8s',
  },
  maxScreenWidth: size.tabletW,
  maxScreenHeight: size.tabletH,
  devices: {
    desktop: `(min-width: ${size.tabletW + 1}px)`,
  },
  queries: {
    desktop: `@media (min-width: ${size.tabletW + 1}px)`,
  },
  helpers: {
    getRemFromPixel(x: number) {
      return `${(x + (x % 2)) / theme.typo.fontSize}rem`;
    },
  },
};

export default theme;
