import styled from 'styled-components';
import { SmallLoader } from '../../atoms/SmallLoader';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
`;

export default function Loader() {
  return (
    <Wrapper>
      <SmallLoader />
    </Wrapper>
  );
}
