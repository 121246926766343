import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';
import { NormalizeStyle, GlobalStyle } from '../styles';
import { AuthProvider } from './AuthContext';

type Props = {
  children: React.ReactNode;
};

export default function AppProviders({ children }: Props) {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <NormalizeStyle />
          <GlobalStyle />
          {children}
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}
