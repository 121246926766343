import styled from 'styled-components';

export const Papper = styled.div`
  background: ${({ theme }) => theme.background.paper};
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: ${({ theme }) => theme.shadows.default};
  border: none;
  outline: none;
  transition: ${({ theme }) => theme.duration.normal};
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;

  @media (max-width: 767px) {
    margin-bottom: 1rem;
  }
`;

export const Hr = styled.hr`
  border: none;
  box-sizing: content-box;
  height: 1px;
  overflow: visible;
  margin: 0.9375rem 0.5rem;
  background-color: ${({ theme }) => theme.colors.gray};
  box-shadow: 1px 1px 1px ${({ theme }) => theme.colors.gray};
`;
