import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;

    @media (max-width: 767px) {
      height: 100%;
    }
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  body {
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-family: ${({ theme }) => theme.typo.fontFamily};
    font-weight: ${({ theme }) => theme.typo.fontWeightRegular};
    font-style: normal;
    line-height: 1.6rem;
    letter-spacing: normal;
    background-color: #3b3b3b;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    overflow: hidden;

    @media (max-width: 767px) {
      overflow: auto;
      height: 100%;
    }
  }
  
  #root {
    display: flex;
    flex-direction: column;
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    @media (max-width: 767px) {
      position: static;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      overflow: auto;
      height: 100%;
    }
  }
  
  a {
    color: ${({ theme }) => theme.colors.blue};
  }
  
  img {
    display:block;
    max-width: 100%;
    height: auto;
  }
`;
