import React, { createContext, Dispatch, useEffect, useState } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  RegisterRequest,
  LoginRequest,
  ErrorsResponse,
  ConfirmEmailRequest,
} from '../api/types';
import { RouteAccessLvl } from '../pages/routes.config';
import { useAuthHook } from './useAuthHook';
import checkTokenExp from '../utils/checkTokenExp';

type Props = {
  children: React.ReactNode;
};
type ConetxtValue = {
  userAccessLvl: RouteAccessLvl;
  setUserAccessLvl: Dispatch<RouteAccessLvl>;
  isLoading: boolean;
  isError: boolean;
  isFulfilled: boolean;
  errors: ErrorsResponse;
  signUp: (rr: RegisterRequest) => void;
  signIn: (rr: LoginRequest) => void;
  confirmEmailAndSingIn: (cr: ConfirmEmailRequest) => void;
  confirmGoogleAndSingIn: (token: string) => void;
  signOut: () => void;
};

const AuthContext = createContext<ConetxtValue>({} as ConetxtValue);

const AuthProvider = ({ children }: Props) => {
  const [userAccessLvl, setUserAccessLvl] = useState<RouteAccessLvl>(() =>
    checkTokenExp() ? RouteAccessLvl.AUTHORIZED : RouteAccessLvl.PUBLIC,
  );

  const {
    status,
    errors,
    signUp,
    signIn,
    signOut,
    confirmEmailAndSingIn,
    confirmGoogleAndSingIn,
  } = useAuthHook(userAccessLvl, setUserAccessLvl);

  useEffect(() => {
    if (!process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY) {
      return;
    }
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey:
          process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userAccessLvl,
        setUserAccessLvl,
        isLoading: status === 'pending',
        isError: status === 'rejected',
        isFulfilled: status === 'fulfilled',
        errors,
        signUp,
        signIn,
        signOut,
        confirmEmailAndSingIn,
        confirmGoogleAndSingIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
