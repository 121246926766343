import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as StyledAtoms from '../StyledAtoms';
import { Animation } from '../Animation';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  flex-grow: 1;
`;

const Text = styled(StyledAtoms.H3)`
  line-height: 1;
  display: block;
  word-spacing: 100vw;
  text-align: center;
`;

export default function SmallLoader() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Animation />
      <Text>{t('Loading please wait.')}</Text>
    </Wrapper>
  );
}
