import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: url('/gfx/auth_banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: block;
  height: 100%;
  width: 100%;
  border-radius: ${({ theme }) => theme.radius};
`;

const Logo = styled.img`
  width: 65%;
  max-height: 55px;
  margin-top: 70px;
  margin-left: 30px;

  @media (max-width: 767px) {
    margin: 30px auto;
    width: 150px;
  }
`;

export default function Banner() {
  return (
    <Wrapper>
      <Logo src="gfx/logo.svg" alt="glaze logo" />
    </Wrapper>
  );
}
