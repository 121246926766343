import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const buttonDisabledCss = css`
  cursor: default;
  background: ${({ theme }) => theme.colors.buttonDisable.background};
  color: ${({ theme }) => theme.colors.buttonDisable.color};
  box-shadow: none;
`;

const buttonCoreCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 0;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.typo.fontWeightLight};
  line-height: 1.125rem;
  padding: 1rem 0;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

export const buttonCss = css`
  ${buttonCoreCss}
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.text};
  text-shadow: ${({ theme }) => theme.shadows.textShadow};
  box-shadow: ${({ theme }) => theme.shadows.default};
  border-radius: ${({ theme }) => theme.radius};
  transition: ${({ theme }) => theme.duration.normal};

  &:hover {
    background: ${({ theme }) => theme.colors.buttonHover.background};
    color: ${({ theme }) => theme.colors.text};
  }
  &:active {
    background: ${({ theme }) => theme.colors.buttonActive.background};
    color: ${({ theme }) => theme.colors.text};
  }
  &:disabled,
  &[disabled] {
    ${buttonDisabledCss}
  }
`;

export const primaryButtonCss = css`
  ${buttonCoreCss}
  background-color: ${({ theme }) => theme.colors.lightBlue};
  color: ${({ theme }) => theme.colors.text};
  text-shadow: ${({ theme }) => theme.shadows.textShadow};
  box-shadow: ${({ theme }) => theme.shadows.default};
  border-radius: ${({ theme }) => theme.radius};
  transition: ${({ theme }) => theme.duration.normal};

  &:hover {
    background: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.text};
  }
  &:active {
    background: ${({ theme }) => theme.colors.lighterBlue};
    color: ${({ theme }) => theme.colors.text};
  }
  &:disabled,
  &[disabled] {
    ${buttonDisabledCss}
  }
`;

export const Button = styled.button`
  ${buttonCss}
`;

export const PrimaryButton = styled.button`
  ${primaryButtonCss}
`;

export const TrialButton = styled.button`
  ${primaryButtonCss};
  background-color: ${({ theme }) => theme.colors.trial};

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${({ theme }) => theme.colors.trialLight};
  }
`;

export const anchorCss = css`
  color: ${({ theme }) => theme.colors.blue};
  transition: ${({ theme }) => theme.duration.normal};
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  text-decoration: underline;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.lightBlue};
    outline: 0;
    text-decoration: none;
  }
`;

export const Anchor = styled.a`
  ${anchorCss}
`;

export const AnchorButton = styled.a`
  text-decoration: none;
  ${buttonCss}
`;

export const Link = styled(RouterLink)`
  ${anchorCss}
`;

export const LinkButton = styled(RouterLink)`
  text-decoration: none;
  ${buttonCss}
`;

export const LinkButtonDisabled = styled(RouterLink)`
  text-decoration: none;
  ${buttonCss}
  ${buttonDisabledCss}
  cursor:pointer;
`;
