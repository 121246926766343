// eslint-disable-next-line no-shadow
export enum ValidationErrors {
  FIELD_REQUIRED = 'FIELD_REQUIRED',
  PASSWORD_MINIMAL_CHARACTERS = 'PASSWORD_MINIMAL_CHARACTERS',
  PASSWORD_LOWERCASE_CHARACTER = 'Password does not meet minimum requirements, add one lowercase character.',
  PASSWORD_UPPERCASE_CHARACTER = 'Password does not meet minimum requirements, add one uppercase character.',
  PASSWORD_DIGIT_CHARACTER = 'Password does not meet minimum requirements, add one digit character.',
  EMAIL_VALID_EMAIL_ADRESS = 'Please enter a valid email address.',
  PASSWORD_MUST_MACH = 'Passwords must match.',
  POLICY_REQUIRED = 'You need to accept our Terms of Service and Privacy Policy to create a Glaze account.',

  ACCOUNT_ALREADY_EXIST = 'An account with this email already exists. Please login using this email address or reset the password.',
  ACCOUNT_WRONG_DATA = 'Wrong email or password, please try again.',
  ACCOUNT_NOT_VERIFIED = 'Account not verified. Check your mailbox.',

  UNEXPECTED_ERROR = 'The server is taking too long to respond. Please check your internet connection and try again or contact your system administrator.',

  FILL_ALL_FIELDS = 'Fill all fields',
}
