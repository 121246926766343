import { Dispatch, SetStateAction } from 'react';
import { UserFormTypes } from '../types';

import { UserState } from './UserContex';

export default function useUserHook(
  userState: UserState,
  setUserState: Dispatch<SetStateAction<UserState>>,
) {
  const handleChangeUserInfo = (selectedItem: string, type: UserFormTypes) => {
    if (
      type === UserFormTypes.DD ||
      type === UserFormTypes.MM ||
      type === UserFormTypes.YYYY
    ) {
      setUserState((prev) => ({
        ...prev,
        birthDate: {
          ...prev.birthDate,
          [type]: selectedItem,
        },
      }));
    } else {
      setUserState((prev) => ({
        ...prev,
        [type]: selectedItem,
      }));
    }
  };

  return {
    handleChangeUserInfo,
  };
}
