import styled from 'styled-components';

export const H1 = styled.h1`
  display: block;
  font-family: ${({ theme }) => theme.typo.headerFontFamily};
  font-weight: ${({ theme }) => theme.typo.fontWeightBold};
  font-size: 1.6875rem;
  line-height: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
`;

export const H2 = styled.h2`
  display: block;
  font-family: ${({ theme }) => theme.typo.headerFontFamily};
  font-weight: ${({ theme }) => theme.typo.fontWeightBold};
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
`;

export const H3 = styled.h3`
  display: block;
  font-family: ${({ theme }) => theme.typo.headerFontFamily};
  font-weight: ${({ theme }) => theme.typo.fontWeightBold};
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
`;

export const H4 = styled.h4`
  display: block;
  font-family: ${({ theme }) => theme.typo.headerFontFamily};
  font-weight: ${({ theme }) => theme.typo.fontWeightBold};
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
`;

export const P = styled.p`
  font-weight: ${({ theme }) => theme.typo.fontWeightLight};
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0;
  margin: 0 0 1rem 0;
`;
