import React from 'react';
import styled from 'styled-components';
import * as StyledAtoms from '../../atoms/StyledAtoms';
import { ChangeLanguage } from '../../molecules/ChangeLanguage';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;

const LeftSection = styled.div`
  display: flex;
  width: 30%;
  flex: 0 0 auto;

  @media (max-width: 767px) {
    width: 100%;
    padding: 1rem;
  }
`;

const RightScrollerSection = styled.div`
  display: flex;
  width: 70%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5.375rem;

  @media (max-width: 767px) {
    padding: 0 1rem;
    width: 100%;
  }
`;

const FormWrapper = styled.div`
  width: 16rem;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Span = styled.span`
  display: block;
`;

const H1 = styled(StyledAtoms.H1)`
  white-space: nowrap;

  @media (max-width: 767px) {
    white-space: normal;
  }
`;

const InfoText = styled(StyledAtoms.P)`
  max-width: 19rem;
  margin-bottom: 1.875rem;
`;

const ChangeLanguageStyled = styled(ChangeLanguage)`
  position: fixed;
  right: 45px;
  top: 30px;

  @media (min-width: 768px) {
    right: 20px;
    top: 20px;
  }
`;

type Props = {
  children?: React.ReactNode;
  leftSection: React.ReactNode;
  header: React.ReactNode | React.ReactNode[];
  paragraph?: React.ReactNode;
  missingEmailParagraph?: React.ReactNode;
};

export default function AuthTemplate({
  children = null,
  leftSection,
  header,
  paragraph,
  missingEmailParagraph,
}: Props) {
  return (
    <Wrapper>
      <ChangeLanguageStyled />
      <LeftSection>{leftSection}</LeftSection>
      <RightScrollerSection>
        <RightSection>
          <FormWrapper>
            <H1>
              {Array.isArray(header)
                ? header.map((line) => <Span key={String(line)}>{line}</Span>)
                : header}
            </H1>
            {paragraph && <InfoText>{paragraph}</InfoText>}
            {missingEmailParagraph && (
              <InfoText>{missingEmailParagraph}</InfoText>
            )}
            {children && <div>{children}</div>}
          </FormWrapper>
        </RightSection>
      </RightScrollerSection>
    </Wrapper>
  );
}
