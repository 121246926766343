import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AuthTemplate } from '../../components/templates/AuthTemplate';
import { Banner } from '../../components/atoms/Banner';

const ColoredTitle = styled.span`
  color: ${({ theme }) => theme.colors.error};
`;

function Header() {
  const { t } = useTranslation();

  return <ColoredTitle>{t('Something went wrong.')}</ColoredTitle>;
}

export default function ConnectionError() {
  const { t } = useTranslation();

  return (
    <AuthTemplate
      leftSection={<Banner />}
      header={<Header />}
      paragraph={t(
        'Sorry, something went wrong there. Double check your internet connection, then please try again.',
      )}
    />
  );
}
