/* eslint-disable no-param-reassign */
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import { ValidationErrors } from '../components/atoms/WarningMessage/validationErrors';

import { getAuthToken, removeAuthToken } from './token';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  withCredentials: true,
  headers: { 'Content-type': 'application/json' },
});

instance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return {
      ...response,
      data: camelcaseKeys(response.data, { deep: true }),
    };
  },
  (error) => {
    const customErrors = error?.response?.data?.errors;
    if (error.response.status === 401) {
      removeAuthToken();
      window.location.href = '/signin';
    }
    if (error.response) {
      error.response.data = {
        ...(error.response.data || {}),
        errors: camelcaseKeys(customErrors, {
          deep: true,
        }),
      };
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
    }

    if (!customErrors) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error.general = [ValidationErrors.UNEXPECTED_ERROR];
    }

    return Promise.reject(error);
  },
);

export default instance;
